@import "/node_modules/bootstrap/dist/css/bootstrap.min.css";

.logo{
  max-height: 75px;
}

img{
  max-height: 100%;
  max-width: 100%;
}

.footer{
  color: rgba(255, 255, 255, 0.55)
}

body{
  background-color: #EDEEF2; 
}

.scroller {
  width: 300px;
  height: 100px;
  overflow-y: scroll;
  scrollbar-color: rebeccapurple green;
}

.overlay-center{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;  
  background-color: rgba(255,0,0,0.6);
}

.home-icon{
  width: 300px;
  height: 225px;
  border: 3px solid white;
  border-radius: 5px;

}

.contrast-text{
  font: 900 2em cookie, cursive;
  color: white;
  text-shadow: 0px 2px 5px #0dcaf0;
}

.hero{
  max-height: 50vh;
  width: 100%;
  object-fit:cover

}

/* h1 {
  font-size: 5.9vw;
}
h2 {
  font-size: 3.0vh;
}
p, a {
  font-size: 3vh !important;
} */